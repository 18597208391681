.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(29, 29, 29, 0.5);
    display: none;
    z-index: 1000;

}

.ModalBox {
    z-index: 2000;
    background-color: rgb(20, 20, 20);
    width: 40vw;
    height: 40vh;
    margin: auto;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius:   13% 90% 10% 87% / 93% 7% 94% 6% ;
    text-align: center;

}
.ModalBox p{
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: left;
    width: 27vw;
    margin-top: 70px;
    
}
.ModalBox h1{

    justify-content: flex-end;
    width: 23vw;
    margin-top: 20px;

}

.headModal{
    
    font-size: 0.75em;
    width: 39vw;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}
#closeButton{
    margin-top: 20px;

}
#TitleBento {
    background: linear-gradient(to right, #8A2BE2, #FFA500);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
@media (max-width: 1600px){
    .headModal{
        width: 35vw;
        font-size: 0.5em;
        margin-left: 20px;
    }
    .ModalBox p{
        margin-top: 20px;
        
    }
}
@media (max-width: 1000px){
    .headModal{
        width: 35vw;
        font-size: 0.5em;
        margin-left: 20px;
    }
}