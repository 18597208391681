.Card{
    /* margin: 50px auto;  */
    width: 400px;
    height: auto; 
    border-radius: 10px;
    overflow: hidden;
}

.Card img{
    width: 100%; 
    height: 220px; 
}

.bloc-Card{
    background: linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.08) 40%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0.03) 100%
    );
    backdrop-filter: blur(15px);

    box-shadow: 
      inset 0 2px 8px rgba(255, 255, 255, 0.1),
      inset 0 -2px 5px rgba(255, 255, 255, 0.05),
      0 5px 15px rgba(255, 255, 255, 0.1),
      0 2px 4px rgba(255, 255, 255, 0.15);
    padding: 20px;
    color: aliceblue;
}
.bloc-Card::after{
content: '';
position: absolute;
top: 0;
left: -50%;
width: 200%;
height: 100%;
background: linear-gradient(
  90deg,
  transparent,
  rgba(255, 255, 255, 0.08),
  transparent
);
transition: 0.5s;
pointer-events: none; 
}
.bloc-Card:hover::after {
left: 0;
}

.titre{
    font-size: 1.5em;
    font-weight: 800;
}

.categorie{
    margin-top: 10px;
    font-weight: 400;
}