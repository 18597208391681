.error-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
}

.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;
  max-width: 600px;
  padding: 0 20px;
}

.title {
  font-size: 15rem;
  font-weight: 700;
  margin: 0;
  line-height: 1;
  background: linear-gradient(45deg, #00bc78, #088ee0);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  animation: pulse 2s infinite;
}

.subtitle {
  font-size: 2.5rem;
  margin: 0;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, #00bc78, #088ee0);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.description {
  color: #a0a0a0;
  margin-bottom: 3rem;
  font-size: 1.2rem;
  line-height: 1.6;
}

.home-button {
  padding: 15px 30px;
  background: linear-gradient(45deg, #00bc78, #088ee0);
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s ease;
  font-size: 1rem;
  box-shadow: 0 5px 15px rgba(0, 188, 120, 0.3);
  position: relative;
  z-index: 3;
}



@media (max-width: 768px) {
  .title {
    font-size: 10rem;
  }
  
  .subtitle {
    font-size: 2rem;
  }
  
  .error-astronaut {
    width: 120px;
    right: 5%;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 7rem;
  }
  
  .subtitle {
    font-size: 1.5rem;
  }
  
  .description {
    font-size: 1rem;
  }
  
  .error-astronaut {
    width: 100px;
    bottom: 10px;
  }
}