.footer-main {
    margin-top: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #181818;
    color: #fff;
    padding: 20px 50px;
    font-family: 'Arial', sans-serif;
}

.footer-left h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-weight: bold;
}

.footer-left p {
    margin: 5px 0;
    font-size: 1rem;
    opacity: 0.8;
}

.footer-right {
    display: flex;
    gap: 20px;
}

.div-media {
    display: flex;
    align-items: center;
    gap: 10px;
}


.div-media img {
    width: 30px;
    height: 30px;
}

.div-media a {
    color: #fff;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
}
@media (max-width: 768px) {
    .footer-main {
        flex-direction: column;
        text-align: center;
        padding: 20px;
    }

    .footer-right {
        flex-direction: column;
        gap: 15px;
        margin-top: 15px;
    }

    .div-media {
        justify-content: center;
    }
}
