.contact-container{
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
}
.contact-container img{
    width: 70%;
}
.Linkedin img{
    width: 80%;
}
.Github{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.Github p{
    margin-top: 50px;
    font-size: 1.6em;
}
.Linkedin{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.Linkedin p{
    font-size: 1.6em;
}
.email{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;

}
.email p{
    margin-top: 10px;
    font-size: 1.6em;
}
@media (max-width: 1000px) {
    .contact-container{
        flex-direction: column;
    }
    .Linkedin img{
        width: 60%;
    }
    .Github img{
        width: 60%;
    }
    .Github{
        margin-bottom: 20px;
    }
    .email{
        margin-bottom: 20px;
    }
}