.container-main{
    color: aliceblue;
    display: flex;
    flex-direction: column;
    width: 1000px;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;

}
.container-main img{
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5));
}
.container-horizontale{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
   
}
.container-verticale-long{
    background-color: #FF6B6B;
    width: 40%;
    height: 410px;
    border-radius: 20px;
    overflow: hidden;

    justify-content: center;
    text-align:center;
    padding: 20px;
}
.container-verticale-long img{
    width: 60%;
    margin-bottom: 20px;
}
.verticale-titre{
    text-align:start;
    font-size: 2em;
    font-weight:800 ;
    color: black;
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.25);
}
.verticale-desc{
    text-align:start;
    font-size: 0.9em;
    font-weight:400 ;
    color: black;

    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);}

.container-verticale-large{
    background-color: #4ECDC4;
    width: 59%;
    margin-left: 20px;
    height: 200px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}
.container-verticale-large .text {
    width: 60%;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 10px;
    margin-right: 20px;
}
.container-verticale-small-1{
    background-color: #FFD93D;
    width: 28%;
    margin-top: -190px;
    padding-left: 10px;
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
}
.container-verticale-small-1 img{
    margin-top: 10px;
    width: 35%;
}
.container-verticale-small-3 img{
    margin-top: 10px;
    width: 35%;
}
.container-verticale-small-2 img{
    width: 60%;
    margin-top: 30px;
}
.container-verticale-small-3{
    background-color: #6C5CE7;
    width: 28%;
    margin-top: -190px;
    margin-left: 20px;
    border-radius: 20px;
    padding-left: 10px;
    padding-top: 10px;
    overflow: hidden;
    text-align: center;
}
.container-verticale-horizontale-large{
    background-color: #A8E6CF;
    width: 70%;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}
.container-verticale-horizontale-large .text {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-top: 180px;
    margin-left: 10px;
    padding-left: 10px;
    margin-right: 20px;
}
.container-verticale-horizontale-large img{
    width: 60%;

}
.container-horizontale-2{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 25px;

}
.container-verticale-small-2{
    background-color: #FF8B94;
    width: 29%;
    margin-left: 20px;
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
    padding-left: 10px;
    padding-top: 10px;
}
.About-Responsive{
    display: none;
}

.overlay{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 20000;
    background-color: red;
}

@media (max-width: 1000px) {
    .container-main{
        width: 700px;
    }
}

@media (max-width: 700px) {
    .container-main{
        display: none;
    }
    .About-Responsive{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .About-Responsive img {
        margin-top: 100px;
        background-color: #121212d2;
        width: 250px;
        border-radius: 10px;
    }
    .About-text{
        display: flex;
        margin-top: 5vh;
        flex-direction: column;
    }
    .About-WhoAmi,.About-Passions,.About-Objectifs{
        background-color: #141415;
        width: 90vw;
        display: flex;
        margin-bottom: 40px;
        flex-direction: column;
        padding: 4vw;
        border-radius: 20px;
        box-shadow: rgba(255, 255, 255, 0.24) 0px 1px 8px;
    }
    .About-WhoAmi > p,.About-Passions > p,.About-Objectifs > p{
        font-weight: 200;
        color: #999999d2;
    }
    .About-Responsive {
        display: flex;
        min-height: 100vh;
        color: white;
        padding: 4rem 2rem;
        gap: 4rem;

        
      }
      
      .About-Responsive img {
        max-width: 600px;
        height: auto;
        object-fit: cover;
        border-radius: 12px;
        transition: transform 0.5s ease;
        position: relative;
      }
      
      
     
      .About-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4rem;
        align-items: center;
      }
      
    
      .About-WhoAmi, .About-Passions, .About-Objectifs {
        padding: 2rem;
        background: linear-gradient(145deg, #0a0a0a, #1a1a1a);
        border: 1px solid #333;
        border-radius: 12px;
        transition: transform 0.3s ease;
      }
      
      
      .About-text h2 {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
        position: relative;
        display: inline-block;
      }
      
      .About-text p {
        color: #aaa;
        line-height: 1.6;
        font-size: 1.1rem;
      }
      
      .About-Passions > div {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1.5rem;
        margin-top: 2rem;
      }
      
      .About-Passions > div > div {
        padding: 1.5rem;
        background: rgba(255, 255, 255, 0.03);
        border-radius: 8px;
        transition: transform 0.3s ease, background-color 0.3s ease;
      }
      
      .About-Passions > div > div:hover {
        transform: translateY(-5px);
        background: rgba(255, 255, 255, 0.05);
      }
      
      .About-Passions h4 {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        color: #fff;
      }
      
      @keyframes fadeIn {
        from {
          opacity: 0;
          transform: translateY(20px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
      
      .About-WhoAmi, .About-Passions, .About-Objectifs {
        animation: fadeIn 0.6s ease forwards;
        opacity: 0;
      }
      
      .About-WhoAmi { animation-delay: 0.1s; }
      .About-Passions { animation-delay: 0.3s; }
      .About-Objectifs { animation-delay: 0.5s; }
      
      /* Media Queries */
      @media (max-width: 1024px) {
        .About-Responsive {
          flex-direction: column;
          align-items: center;
          padding: 2rem 1rem;
          gap: 2rem;
        }
      
        .About-Responsive img {
          max-width: 300px;
        }
      
        .About-text {
          width: 100%;
          gap: 2rem;
        }
      
        .About-Passions > div {
          grid-template-columns: 1fr;
        }
      }
      
}
