.herroBanner {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.herroBanner::before,
.herroBanner::after {
  content: '';
  position: absolute;
  width: 24rem;
  height: 24rem;
  border-radius: 50%;
  filter: blur(100px);
  z-index: 1;
}

.herroBanner::before {
  background: rgba(168, 85, 247, 0.3);
  top: -4rem;
  left: -12rem;
}

.herroBanner::after {
  background: rgba(236, 72, 153, 0.3);
  bottom: -4rem;
  right: -12rem;
}

.herro {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.herro-description {
  max-width: 800px;

  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.hero-title {
  font-size: clamp(2.5rem, 8vw, 4.5rem);
  margin-bottom: 1.5rem;
  line-height: 1.2;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  height: 22vh;
}

.gradient-text {
  background: linear-gradient(to right, #a855f7, #ec4899, #a855f7);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-left: 0.5rem;
}

.hero-subtitle {
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  color: #9ca3af;
  margin-bottom: 3rem;
  margin-top: 2vh;
  text-align: center;
}

.button-div {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

#herro-button1,
#herro-button2 {
  padding: 1rem 2rem;
  border-radius: 9999px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

#herro-button1 {
  background: linear-gradient(to right, #a855f7, #ec4899);
  color: white;
}

#herro-button1:hover {
  opacity: 0.9;
}

#herro-button2 {
  background: transparent;
  color: white;
  border: 2px solid #a855f7;
}

#herro-button2:hover {
  background-color: rgba(168, 85, 247, 0.1);
}

#DownloadButton {
  display: inline-block;
  margin-bottom: 2rem;
  text-decoration: none;
}

#ScrollButton {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}

#DownloadButton {
  margin-top: 20px;
}

strong {
  color: #F2C1B6;
}

#ScrollButton {
  width: 10vw;
  display: flex;
  justify-content: right;
}

@media (max-width: 768px) {
  .herro {
    text-align: center;
  }

  .button-div {
    flex-direction: column;
    align-items: stretch;
  }

  #herro-button1,
  #herro-button2 {
    display: block;
    text-align: center;
  }

  #ScrollButton {
    display: none;
  }
}

@keyframes blobMovement {
  0% { transform: translate(0, 0); }
  50% { transform: translate(50px, 50px); }
  100% { transform: translate(0, 0); }
}

.herroBanner::before {
  animation: blobMovement 10s ease-in-out infinite;
}

.herroBanner::after {
  animation: blobMovement 10s ease-in-out infinite reverse;
}

@media (max-width: 1024px) {
  .hero-title {
    font-size: clamp(3rem, 6vw, 3.5rem);
  }

  .hero-subtitle {
    font-size: clamp(1rem, 3vw, 1.25rem);
  }

  .button-div {
    flex-direction: row;
    align-items: center;
  }

  #herro-button1,
  #herro-button2 {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: clamp(3rem, 5vw, 2.5rem);
  }

  .hero-subtitle {
    font-size: clamp(0.875rem, 2.5vw, 1rem);
    max-width: 80vw;
    margin-top: 3vh;
  }

  .button-div {
    flex-direction: row;
    align-items: center;
  }

  #herro-button1,
  #herro-button2 {
    width: 45vw;
    text-align: center;
  }

  .gradient-text {
    margin-left: 0;
  }
}