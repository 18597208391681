.project-container {
    min-height: 100vh;
    padding: 2rem;

}
.project-container::before,
.project-container::after {
  content: '';
  position: absolute;
  width: 24rem;
  height: 24rem;
  border-radius: 50%;
  filter: blur(100px);
  z-index: 1;
}

.project-container::before {
  background: rgba(168, 85, 247, 0.3);
  top: -4rem;
  left: -12rem;
}

.project-container::after {
  background: rgba(236, 72, 153, 0.3);
  bottom: -4rem;
  right: -12rem;
}

.project-card {
    max-width: 1200px;
    margin: 2rem auto;
    background: transparent;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    transition: transform 0.3s ease;
    margin-top: 20vh;
}



.project-image {
    width: 50%;
    object-fit: cover;
    border-radius: 20px 0 0 20px;
    overflow: hidden;
    border-radius: 20px;
}

.project-info {
    width: 50%;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project-title {
    font-size: 2.5rem;
    color: #fffafa;
    margin-bottom: 1.5rem;
}

.project-description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #ffffff;
    margin-bottom: 2rem;
}

.tech-stack {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;
}

.tech-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: #787a7c98;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    color: whitesmoke;
}



.tech-item img {
    width: 24px;
    height: 24px;
}

.tech-item p {
    margin: 0;
    font-size: 0.9rem;
    color: whitesmoke;
}

.github-link {
    display: inline-block;
    margin-top: auto;
}
@media (max-width: 968px) {
    .project-card {
        flex-direction: column;

    }

    .project-image,
    .project-info {
        width: 100%;
        
    }

    .project-image {
        border-radius: 20px;
        max-height: 400px;
    }

    .project-info {
        padding: 2rem;
    }

    .project-title {
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .project-container {
        padding: 1rem;
    }

    .project-card {
        margin: 1rem auto;
    }

    .project-info {
        padding: 1.5rem;
    }

    .project-title {
        font-size: 1.75rem;
    }

    .tech-stack {
        gap: 1rem;
    }
}