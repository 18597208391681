@font-face {
    font-family: 'Faculty Glyphic';
    src: url('/public/fonts/Poppins-Light.ttf') format('truetype');
    font-display: swap;
  }
  

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    font-weight: 600;
    font-family: "Faculty Glyphic";
}
html, body {
    background-color: black;
    position: relative;
    color: aliceblue;
    scroll-behavior: smooth;
    overflow-x: hidden;
    cursor: none;
    min-height: 100vh;
    z-index: 0;
}

body::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
        linear-gradient(to right, rgba(255,255,255,0.1) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(255,255,255,0.1) 1px, transparent 1px);
    background-size: 65px 65px;
    background-position: center;
    -webkit-mask-image: radial-gradient(circle at center, 
        black 0%,
        black 50%,
        transparent 10%
    );
    mask-image: radial-gradient(circle at center, 
        black 0%,
        black 70%,
        transparent 100%
    );
    pointer-events: none;
    z-index: -1;
    animation: gridAppear 1.5s ease-out forwards;
    transform-origin: center;
    opacity: 0;
}
@keyframes gridAppear {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.glow {
    position: absolute;
    width: 2000px;
    height: 1000px;
    background: radial-gradient(ellipse, 
        rgba(255, 255, 255, 0.3) 10%,
        rgba(255, 255, 255, 0.1) 20%,
        transparent 60%
    );
    border-radius: 35%;
    filter: blur(100px);
    pointer-events: none;
    top: -300px;
    left: 400px;
    transform: rotate(-50deg);
    animation: glowAppear 1.8s ease-out forwards;
    z-index: 200;
    z-index: 200;
}
@keyframes glowAppear {
    0% {
        opacity: 0;
        transform: rotate(-50deg) translateY(100px);
    }
    100% {
        opacity: 1;
        transform: rotate(-50deg) translateY(0);
    }
}

a{
    color: aliceblue;
    text-decoration: none;
}

.grid-project {
    margin-top: 100px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
    gap: 100px; 
    padding: 40px; 

    justify-items: center; 
}
.projectPage{
    margin-top: 50px;
}
.projectPage h1{
    color: aliceblue;
    text-align: center;
    font-size: 4em;
    font-weight: 900;
}

#contactText{
    margin-top: 20px;
    text-align: center;
    font-size: 1.2em;
    font-weight: 700 ;

}
.work-app-container{
    margin-top: 100px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.cursor {
    width: 40px;
    height: 40px;
    border: 1px dashed white;
    border-radius: 50%;
    position: fixed;
    z-index: 200000;
    pointer-events: none;
    animation: Anime1 1.5s infinite alternate;
    mix-blend-mode: difference;
    will-change: transform;
  }
.cursor::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 5px;
    height: 5px;
    background: #F2C1B6;
    border-radius: 50%;
}
.expand{
    position: fixed;
    animation: Anime2 .5s;
    background: #F2C1B6;
    border: 1px solid #F2C1B6;
}

@keyframes Anime1 {
    from{
        transform: scale(1) rotate(0deg);
    }
    to{
        transform: scale(0.7) rotate(90deg);
    }
}

@keyframes Anime2 {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(2);
    }
    100%{
        transform: scale(1);
        opacity: 0;
    }
    
}



@media (max-width: 1200px) {
    .work-app-container{
        flex-direction: column;
        align-items: center;
    }
    .cursor{
        display: none;
    }
    body{
        cursor: pointer;
    }
    #WorkAt{
        font-size: 3em;
    }
}