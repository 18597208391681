/* Container principal */
.work-container {
    width: 300px;
    background: linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.08) 40%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0.03) 100%
    );
    backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 
      inset 0 2px 8px rgba(255, 255, 255, 0.1),
      inset 0 -2px 5px rgba(255, 255, 255, 0.05),
      0 5px 15px rgba(255, 255, 255, 0.1),
      0 2px 4px rgba(255, 255, 255, 0.15);
    border-radius: 15px;
    padding: 20px;
    overflow: hidden;
    text-align: center;
    margin-right: 100px;
    margin-left: 100px;
    color: black;
    justify-content: space-around;
  }
  .work-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.08),
      transparent
    );
    transition: 0.5s;
    pointer-events: none; 
  }
  .work-container:hover::after {
    left: 0;
  }
  
  .work-container img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    margin: 10px 0;
  }
  
  .work-nom {
    color: #ffffff;
    font-size: 22px;
    font-weight: 800;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .work-boite {
    color: #ffff;
    font-size: 16px;
    margin: 5px 0;
    font-weight: 500;
  }
  
  .work-durée {
    display: inline-block;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.039);
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    margin-top: 10px;
    backdrop-filter: blur(5px);
  }
  

  @media (max-width: 1068px) {
    .work-container {
      margin-bottom: 100px;
    }
    .work-durée {

      color: black;

    }
  }